export default {
  //获取组织架构列表(区域列表)
  GET_LIST: '/api/admin/organ/list',
  //获取详细信息
  GET_DETAIL: '/api/admin/organ/info',
  //新增组织(新增区域)
  ADD: '/api/admin/organ/create',
  //编辑组织(编辑区域)
  UPDATE: '/api/admin/organ/update',
  //删除组织(删除区域)
  DELETE: '/api/admin/organ/delete'
}
