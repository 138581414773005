export default {
    //获取新增删除标签
    tag: {
        //获取标签列表
        GET_LIST: '/api/admin/hotspot/classify/list',
        //新增标签
        ADD: '/api/admin/hotspot/classify/create',
        //编辑标签
        UPDATE: '/api/admin/hotspot/classify/update',
        //删除标签
        DELETE: '/api/admin/hotspot/classify/delete'
    },
    //下发
    send: {
        //执行发放操作
        DISPATCH_SEND: '/api/admin/hotspot/publish',
        //获取下发列表
        GET_LIST: '/api/admin/hotspot/publish/list',
        //编辑下发信息
        UPDATE: '/api/admin/hotspot/publish/update',
        //删除下发信息
        DELETE: '/api/admin/hotspot/publish/delete'
    },
    //获取热点推送-模板-列表
    GET_LIST: '/api/admin/hotspot/package/list',
    //热点推送-模板-新增
    ADD: '/api/admin/hotspot/package/create',
    //热点推送-模板-编辑更新
    UPDATE: '/api/admin/hotspot/package/update',
    //删除模板
    DELETE: '/api/admin/hotspot/package/delete',
    //审核通过
    VERIFY_PASS: '/api/admin/hotspot/package/pass',
    //审核驳回
    VERIFY_UN_PASS: '/api/admin/hotspot/package/reject',
}
