<template>
  <div class="pv-tab-pane" v-if="isActive">
    <div class="pane-inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/lib/permission';
export default {
  props: {
    name: String,
    label: String,
    permission: Array
  },
  data() {
    return {
      activeName: ''
    };
  },
  computed: {
    isActive() {
      return this.activeName === this.name;
    }
  },
  methods: {
    checkPermission,
    setActiveName(name) {
      this.activeName = name;
    }
  },
  mounted() {
    const checkPermissionResult = this.permission?checkPermission(this.permission):true;
    if(checkPermissionResult){
        this.$parent.addPane(this);
    }
  },
  beforeDestroy() {
    this.$parent.removePane(this);
  }
}
</script>

<style lang="scss" scoped>
.pane-inner {
  //background-color: #ffffff;
//   overflow: auto;
}
</style>
