<template>
  <div class="content-layout">
    <div class="crumb-box" v-if="crumb && crumb.length>0">
      <el-breadcrumb slot="crumb" separator="/">
        <el-breadcrumb-item
          v-for="item in crumb"
          :key="item.path"
          :to="item.path?({path:item.path}):undefined">{{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    crumb: Array
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.content-layout {
  padding: 17px;
}

.crumb-box {
  margin-bottom: 20px;
}
</style>
