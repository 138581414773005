<template>
  <el-date-picker
    :size="size"
    :type="type"
    :format="format"
    :value-format="valueFormat"
    :range-separator="rangeSeparator"
    v-model="pickerValue"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder">
  </el-date-picker>
</template>

<script>
export default {
  props: {
    size: String,
    type: { type: String, default: 'daterange' },
    format: { type: String, default: 'yyyy-MM-dd' },
    valueFormat: { type: String, default: 'yyyy-MM-dd' },
    rangeSeparator: { type: String, default: '至' },
    value: String,
    startPlaceholder: { type: String, default: '开始时间' },
    endPlaceholder: { type: String, default: '结束时间' },
  },
  data() {
    return {};
  },
  computed: {
    pickerValue: {
      get() {
        if (this.value) {
          return this.value.split(',');
        }
        return [];
      },
      set(range) {
        let val = '';
        if (range && range[0] && range[1]) {
          val = range.join(',');
        }
        this.$emit('input', val);
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
