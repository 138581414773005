import store from '@/store'

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
    const userAuth = store.getters && store.getters.userAuth;
    if (value && value instanceof Array && value.length > 0) {
        const permissionAuthkey = value

        const hasPermission = (userAuth.settings && userAuth.settings.length > 0) ? userAuth.settings.some(authkey => {
            return permissionAuthkey.includes(authkey)
        }) : true;

        return hasPermission;
    } else {
        console.error(`need authkey! Like v-permission="['1-1','1-2']"`);
        return false;
    }
} 
