const chatmsg = {
    state: {
        topNewMsg: 0,
        topUser: {
            weixin: 0,
            douyin: 0,
        },
        latestMsg: null,
        messageIdsList: [], //用于储存当前已经显示的消息msg_id，避免显示重复的消息
        chattingState: false
    },
    mutations: {
        SET_TOP_NEWMSG: (state, msgNumber) => {
            state.topNewMsg = msgNumber;
        },
        SET_TOP_USER: (state, users) => {
            state.topUser = users;
        },
        UPDATE_LATESTMSG: (state, msg) => {
            state.latestMsg = msg;
        },
        UPDATE_MSGIDS_LIST: (state, msgid) => {
            if(msgid.split(',').length>1){
                state.messageIdsList = msgid.split(',');
            }else{
                state.messageIdsList.push(msgid);
            }
        },
        UPDATE_CHATTING_STATE: (state, chatting) => {
            state.chattingState = chatting;
        }
    },
    actions: {
        setTopNewmsg({commit}, msgNumber) {
            commit('SET_TOP_NEWMSG', msgNumber);
        },
        setTopUser({commit}, users) {
            commit('SET_TOP_USER', users);
        },
        updateLatestMsg({commit}, msg) {
            commit('UPDATE_LATESTMSG', msg);
        },
        updateMsgIdsList({commit}, msgid) {
            commit('UPDATE_MSGIDS_LIST', msgid);
        },
        updateChattingState({commit}, chatting) {
            commit('UPDATE_CHATTING_STATE', chatting)
        }
    }

};
export default chatmsg;