export default {
  //经销商热度
  dealer: {
    //获取列表
    GET_LIST: '/api/admin/douyin/getDealerHotVideoList',
    //获取经销商详情
    DETAIL: '/api/admin/douyin/getDealerVideoList',
    ACCOUNT_LIST: '/api/admin/douyin/getUsersVideoList' 
  },
  //视频热度
  video: {
    //获取列表
    GET_LIST: '/api/admin/douyin/getHotVideoList',
    //视频收藏
    SET_FOCUS: '/api/admin/douyin/videoFocus',
    //视频存至内容中心
    SAVE_VIDEO_AS: '/api/admin/douyin/move'
  }
}
