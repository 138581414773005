const page = {
    state: {
        pageEdit: true,
        includedComponents: ''
    },
    mutations: {
        UPDATE_PAGE_EDIT: (state, edit) => {
            state.pageEdit = edit
        },
        SHOW_KEEP_ALIVE:  (state, name) => {
            state.includedComponents = name;
        }

    },
    actions: {
        updatePageEdit({commit}, edit) {
            commit('UPDATE_PAGE_EDIT', edit);
        },
        showKeeepAlive({commit}, name) {
            commit('SHOW_KEEP_ALIVE', name);
        }
    }

};
export default page;
