<template>
  <div class="pv-tabs">
    <div class="tab-btns">
      <div
        ref="tabbtn"
        class="tab-btn"
        v-for="item in panes"
        :key="item.name"
        @click="handleTabBtnClick(item)"
        :class="{active:item.name===active}" v-permission="item.permission">{{ item.label }}
      </div>
      <slot name="tabsAppend"></slot>
    </div>
    <div class="tab-pane-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: String
  },
  data() {
    return {
      panes: [],
      panesNames: []
    };
  },
  watch: {
    active: {
      handler() {
        this.panesSync();
      },
      immediate: true
    },
    panes: {
      handler() {
        this.panesSync();
      },
      immediate: true,
      deep: true
    },
  },
  computed: {},
  methods: {
    handleTabBtnClick(pane) {
      this.$emit('change', pane.name);
    },
    panesSync() {
        this.panes.forEach(pane => {
            this.panesNames.push(pane.name);
            pane.setActiveName(this.active);
        });
        this.$nextTick(()=>{
            if(this.panesNames.indexOf(this.active) == -1){
                this.$emit('change', this.panesNames[0]);
            }
        })
    },
    addPane(pane) {
      this.panes.push(pane);
    },
    removePane(pane) {
      const index = this.panes.indexOf(pane);
      index >= 0 && this.panes.splice(index, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.pv-tabs {
  display: block;
}

.tab-btns {
  white-space: nowrap;
}

.tab-btn {
  display: inline-block;
  vertical-align: top;
  background-color: #dde2e8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  padding: 10px 25px;
  color: #616161;
  margin-right: 1px;

  &.active {
    background-color: #ffffff;
    color: #3d82fc;
    font-weight: bold;
  }

}

.tab-pane-box {
  clear: both;
}
</style>
