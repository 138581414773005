const getters = {
    pageEdit:state => state.page.pageEdit,
    includedComponents: state => state.page.includedComponents,

    forms:state => state.form.forms,
    formEdit:state => state.form.formEdit,
    isAdmin:state => state.form.isAdmin,
    
    userAuth:state => state.user.userAuth,

    topNewMsg: state => state.chatmsg.topNewMsg,
    topUser: state => state.chatmsg.topUser,
    latestMsg: state => state.chatmsg.latestMsg,
    messageIdsList: state => state.chatmsg.messageIdsList,
}
export default getters