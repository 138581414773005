export default {
    //获取新增删除表单
    form: {
      //获取表单列表
      GET_LIST: '/api/admin/form/list',
      //查看表单详情
      GET_INFO: '/api/admin/form/info',
      //新增表单
      ADD: '/api/admin/form/create',
      //编辑更新表单
      UPDATE: '/api/admin/form/update',
      //删除表单
      REMOVE: '/api/admin/form/delete'
    },
    //创建专题
    ADD: '/api/admin/pages/create',
    //编辑更新专题
    UPDATE: '/api/admin/pages/update',
    //查看专题详情
    GET_INFO: '/api/admin/pages/info',
  }
  