import cookie from '@/lib/cookie';
import config from '@/config';

const { tokenKey } = config;

export const getToken = () => {
  return cookie.get(tokenKey);
};

export const setToken = (token) => {
  cookie.set(tokenKey, token);
};

export const clearToken = () => {
  cookie.remove(tokenKey)
};
