export default {
  //素材/内容
  material: {
    GET_LIST: '/api/admin/report/material/list',
    //经销商详情 ?material=
    DEALER_DETAIL_LIST: '/api/admin/report/dealer/list'
  },
  //经销商
  dealer: {
    GET_LIST: '/api/admin/report/dealer/list',
    //内容详情 ?dealer=
    MATERIAL_DETAIL_LIST: '/api/admin/report/material/list',
    //顾问详情 ?dealer=
    ADVISER_DETAIL_LIST: '/api/admin/report/member/list',
  },
  //顾问
  adviser: {
    GET_LIST: '/api/admin/report/member/list',
    //素材详情  ?uid=
    MATERIAL_DETAIL_LIST: '/api/admin/report/material/list',
  },
  //总览
  total: {
    GET_TOTAL: '/api/admin/report/total'
  } 
}
