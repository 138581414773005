export default {
  //角色--用户组
  //获取列表
  GET_LIST: '/api/admin/group/list',
  //新增角色
  ADD: '/api/admin/group/create',
  //编辑角色
  UPDATE: '/api/admin/group/update',
  //获取所有权限列表
  GET_AUTH_INFO: '/api/admin/group/auth/info',
  DELETE: '/api/admin/group/delete',
}
