export default {
  //上传文件
  UPLOAD: '/api/admin/material/upload',
  //获取省市区列表
  GET_REGION_LIST: '/api/admin/city/list',
  //获取区域列表
  GET_ORG_LIST: '/api/admin/organ/list',
  // 获取上传ask
  GET_ASK: '/api/admin/getask'
}
