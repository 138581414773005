<template>
  <svg :class="svgClass" aria-hidden="true" @click="handleClick">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
const req = require.context('@/assets/svgIcon', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req);

export default {
  name: 'svg-icon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor; //此属性为更改svg颜色属性设置
  stroke: currentColor;
  overflow: hidden;
}
</style>
