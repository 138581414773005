<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :appendToBody="appendToBody"
    :customClass="customClass"
    center
    destroy-on-close
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="showClose">

    <div v-if="visible">
      <slot></slot>
    </div>
    <div slot="footer" class="dialog-footer-box">
      <el-button v-if="cancelEnable" @click="visible = false" size="small">{{ cancelText }}</el-button>
      <el-button v-if="okEnable" type="primary" @click="handleOkClick" size="small" :loading="submiting">{{ okText }}</el-button>
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    width: {
      type: String,
      default: '700px'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    okText: {
      type: String,
      default: '确定'
    },
    cancelEnable: {
      type: Boolean,
      default: true
    },
    okEnable: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    customClass: String,
    appendToBody: Boolean
  },
  data() {
    return {
      visible: false,
      submiting: false
    };
  },
  computed: {},
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.submiting = false;
    },
    handleOkClick() {
      this.submiting = true;
      this.$emit('submit', () => {
        this.submiting = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer-box {
  text-align: center;

  :deep {
    .el-button {
      width: 140px;
      height: 45px;
      font-size: 14px;
    }
  }
}
:deep .el-dialog{
    margin: 10vh auto!important;;
}
</style>
