<template>
  <span v-if="pageInfo">{{ result }}</span>
</template>

<script>

import _ from 'lodash';

const hasClass = (el, className) => {
  if (el && el.className) {
    const classList = el.className.split(' ');
    return classList.includes(className);
  }
  return false;
}

export default {
  props: {
    data: Object
  },
  data() {
    return {
      pageInfo: null
    };
  },
  computed: {
    indexNum() {
      return this.data.$index || 0;
    },
    result() {
      const current = (_.get(this.pageInfo, 'current') || 1) * 1;
      const pageSize = (_.get(this.pageInfo, 'pageSize') || 10) * 1;
      return this.indexNum + ((current - 1) * pageSize) + 1;
    }
  },
  methods: {
    getPageInfo() {
      let $list = this.$el;
      if ($list) {
        while (!hasClass($list, 'pv-list')) {
          $list = $list.parentElement;
          if (($list.tagName + '').toLocaleLowerCase() === 'body') {
            $list = null;
            break;
          }
        }
        if ($list) {
          let pageInfo = $list.getAttribute('data-page-info');
          pageInfo = JSON.parse(pageInfo);
          return pageInfo;
        }
      }
      return null;
    },
  },
  mounted() {
    this.pageInfo = this.getPageInfo();
  }
}
</script>

<style lang="scss" scoped></style>
