import store from '@/store'

function checkPermission(el, binding, vnode) {
  const { value } = binding
  const userAuth = store.getters && store.getters.userAuth;
//   console.log("userAuth=>>>>", userAuth.settings);

  if (value && value instanceof Array) { 
    if (value.length > 0) {
      const permissionAuthkey = value
     
      const hasPermission = (userAuth && userAuth.settings && userAuth.settings.length>0)?userAuth.settings.some(authkey => {
        return permissionAuthkey.includes(authkey)
      }):true;

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
        var parent = vnode.context.$parent;
        var index = parent.$children.indexOf(vnode.context);
        parent.$children.splice(index, 1);
      }
    }
  } else {
    // throw new Error(`need authkey! Like v-permission="['1-1','1-2']"`);
  }
}

export default {
  inserted(el, binding, vnode) {
    checkPermission(el, binding, vnode)
  },
  update(el, binding, vnode) {
    checkPermission(el, binding, vnode)
  }
}
