<template>
  <el-input ref="input" :key="inputType" :size="size" :value="value" :placeholder="placeholder" @input="handleInput" :type="inputType">
    <svg-icon v-if="value" :iconClass="iconClass" class-name="switch-icon" slot="suffix" @click="handleIconClick"></svg-icon>
  </el-input>
</template>

<script>
export default {
  props: {
    size: String,
    value: String,
    placeholder: String
  },
  data() {
    return {
      inputType: 'password'
    };
  },
  computed: {
    iconClass() {
      if (this.inputType === 'text') {
        return 'eye-open';
      } else if (this.inputType === 'password') {
        return 'eye';
      }
      return '';
    },
  },
  methods: {
    async handleIconClick() {
      if (this.inputType === 'text') {
        this.inputType = 'password';
      } else if (this.inputType === 'password') {
        this.inputType = 'text';
      }
      await this.$nextTick();
      this.$refs.input.focus();
    },
    handleInput(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/config";

.switch-icon {
  color: $--color-primary;
  cursor: pointer;
  margin-right: 10px;
}
</style>
