const form = {
    state: {
        forms: [],
        formEdit:true,
        isAdmin:false
    },
    mutations: {
        UPDATE_FORM_EDIT: (state, edit) => {
            state.formEdit=edit
        },
        UPDATE_ADMIN_STATE: (state, isAdmin) => {
            state.isAdmin=isAdmin
        },
        ADD_FORM: (state, form) => {
            if (form.index) {
                // let position = null;
                if (!state.forms.some(item => item.index === form.index)) {
                    state.forms.push(form)
                }
            }
        },
        DELETE_FORM: (state, form) => {
            if (form.index) {
                let position = null;
                state.forms.forEach((item, index) => {
                    if (form.index === item.index) {
                        position = index;
                    }
                });
                if (position !== null) {
                    state.forms.splice(position, 1)
                }
            }
        },

        CLEAR_FORM: (state) => {
            state.forms=[];
        },

    },
    actions: {
        updateFormEdit({commit}, edit) {
            commit('UPDATE_FORM_EDIT', edit);
        },
        addForm({commit}, form) {
            commit('DELETE_FORM', form);
            commit('ADD_FORM', form);
        },
        deleteForm({commit}, form) {
            commit('DELETE_FORM', form);
        },
        clearForm({commit}) {
            commit('CLEAR_FORM');
        },
        updateAdminState({commit},  isAdmin) {
            commit('UPDATE_ADMIN_STATE', isAdmin);
        },

    }

};
export default form;
