export default {
  // 获取所有经销商数据(区域联动 树状结构)
  GET_DEALER_TREE_DATA: '/api/admin/dealer/publish/select',
  //获取经销商列表
  GET_LIST: '/api/admin/dealer/list',
  //新增经销商
  ADD: '/api/admin/dealer/create',
  //编辑经销商
  UPDATE: '/api/admin/dealer/update',
  //更换管理员
  OWNER_UPDATE: '/api/admin/dealer/owner/update',
  //经销商绑定企业微信
  BIND_WX_WORK: '/api/admin/wxwork/config',
  //导入经销商
  IMPORT: '/api/import/dealer',
  //导入模版文件下载地址
//   IMPORT_TEMPLATE_FILE_URL: 'https://oss.symat.cn/material/import/dealer.xlsx',
  IMPORT_TEMPLATE_FILE_URL: '/import/dealer.xlsx',
  //查询导入的结果
  GET_IMPORT_RESULT: '/api/import/success/list',
  //重置管理员密码
  RESET_ADMIN_PASSWORD: '/api/admin/dealer/password/reset',
  //获取经销商绑定的微信客服信息
  BIND_AISBOT_INFO: '/api/admin/wxkefu/contact'
}
