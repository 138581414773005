import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    //初始页面
    path: '/',
    name: 'root',
    component: () => import(/* webpackChunkName: "root" */ '../views/root/index.vue')
  },
  {
    //登录
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    //忘记密码/重置密码
    path: '/reset/password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/resetPassword/index.vue')
  },
  {
    //登录后显示的界面
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/index/index.vue'),
    children: require('./home')
  },
  {
    //专题预览
    path: '/preview',
    name: 'preview',
    component: () => import(/* webpackChunkName: "home/subject" */ '../views/home/subject/preview.vue'),
    props: (route) => ({ id: route.query.id }),
  },
]

const router = new VueRouter({
  routes
})

export default router
