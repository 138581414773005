/** permission权限设计
 * 
 * store存储 用户权限值setting 【isadmin=1超管用户】
 * 
 * 1.路由添加角色值 meta authkey
 *  设置路由拦截 路由有meta-key时 非超管用户 用户信息setting中没有该值，则没权限跳转到-无权限提示界面
 * 2.操作按钮使用v-permission自定义指令判断是否有权限可使用，tab切换引入lib/permission方法判断tab-btn是否存在可点
 * 3.左侧菜单menu.js中过滤菜单权限
 * 
*/
import router from './router';
import store from './store';
// import NProgress from 'nprogress';
// import 'nprogress/nprogress.css';
import { getUserInfo } from '@/service/user';
// import getPageTitle from '@/common/get-page-title';

// NProgress.configure({ showSpinner: false });

const CheckRouterAuth = (authkey, settings)=>{
    if(authkey && settings){
        return (authkey && authkey.length>0)? authkey.some(key=>{ return settings.includes(key)}): false;
    }else{
        return false;
    }
}

router.beforeEach(async(to, from, next) => {
    // NProgress.start();
    // document.title = getPageTitle(to.meta.title);

    if(to.meta && to.meta.authkey){
        //需要鉴权
        const userAuth = store.getters.userAuth;
        if(!userAuth){
            const userInfo = await getUserInfo();
            if(userInfo){
                store.dispatch('setUserAuth', userInfo);
                const _userauth = userInfo.member;
                if(_userauth.isadmin && _userauth.isadmin == 1){
                    next();
                }else{
                    const hasAuth = CheckRouterAuth(to.meta.authkey, _userauth.settings);
                    if(!hasAuth){
                        next(`/home/noPermission`);
                        // NProgress.done();
                    }else{
                        next();
                    }
                }
            }else{
                next();
            }
        }else{
            if(userAuth.isadmin && userAuth.isadmin == 1){
                next();
            }else{
                const hasAuth = CheckRouterAuth(to.meta.authkey, userAuth.settings);
                if(!hasAuth){
                    next(`/home/noPermission`);
                    // NProgress.done();
                }else{
                    next();
                }
            }
        }
    }else{
        next();
    }
})

router.afterEach(() => {
    // NProgress.done();
})
  