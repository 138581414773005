let globalData = null;

export const getGlobalData = () => {
  return globalData;
};

export default {
  install(Vue) {
    globalData = new Vue({
      data() {
        return {
          save: {}
        }
      },
      methods: {
        get(key) {
          let result = globalData.save[key];
          if (!result) {
            this.$set(this.save, key, null);
          }
          return result;
        },
        set(key, value) {
          this.$set(this.save, key, value);
          this.$emit('change');
        },
        clear() {
          this.save = {};
        }
      }
    });
    Vue.prototype.$globalData = globalData;
  }
}
