<template>
  <div class="pv-list" :data-page-info="pageInfoString">
    <div class="search-wrap" ref="searchWrap">
      <slot name="search"></slot>
    </div>
    <div v-if="tableHeight" class="table-wrap">
        <el-table
        ref="table"
        v-loading="loading"
        element-loading-text="拼命加载中"
        :data="finalTableData"
        :maxHeight="maxHeight"
        :height="tableHeight"
        stripe
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange">
        <slot></slot>
        </el-table>
    </div>
    <div class="page-bar-box" ref="pageBarBox">
      <pv-pagination :disabled="loading" :current="pageInfo.current" :pageCount="pageInfo.pageCount" @change="handlePageCurrentChange" :pageSize="pageInfo.pageSize" @changeSize="handleSizeChange"/>
    </div>
    <slot name="append"></slot>
  </div>
</template>

<script>
import request from '@/lib/request';
import _ from 'lodash';

export default {
  props: {
    api: String,
    exportApi: String,
    maxHeight: [String, Number],
    params: {
      type: Object,
      default: function () {
        return {};
      }
    },
    requestMethod: {
      type: String,
      default: 'post'
    },
    dataFormat: Function,
    rowKey: {
      type: String,
      default: 'id'
    },
    exportRowKey: {
      type: String,
      default: 'id'
    },
  },
  inject: {
    showTotal: {
        value: "showTotal",
        default: null
    }
  },
  name: 'pvList',
  data() {
    return {
      pageInfo: { current: 1, pageCount: 1, pageSize: 100 },
      searchInfo: {},
      tableData: [],
      checkedList: [],
      loading: false,
      sort: '',
      order: '',
      tableHeight: 0
    };
  },
  computed: {
    pageInfoString() {
      return JSON.stringify(this.pageInfo);
    },
    finalTableData() {
      if (this.dataFormat) {
        return this.dataFormat(this.tableData);
      }
      return this.tableData;
    }
  },
  methods: {
    getMatchRow(row) { //获取选中的对应行
      return this.checkedList.find(item => {
        if (this.rowKey) {
          return item[this.rowKey] === row[this.rowKey];
        } else {
          return _.isEqual(item, row);
        }
      });
    },
    checkRow(row) { //选中某一行
      const matchRow = this.getMatchRow(row);
      if (!matchRow) {
        this.checkedList.push(row);
      }
    },
    unCheckRow(row) { //取消选中某一行
      const matchRow = this.getMatchRow(row);
      if (matchRow) {
        const index = this.checkedList.indexOf(matchRow);
        this.checkedList.splice(index, 1);
      }
    },
    handleSelectionChange(rows) { //选中的行有变化
      if (!this.loading) {
        this.tableData.forEach(item => {
          if (rows.includes(item)) {
            this.checkRow(item);
          } else {
            this.unCheckRow(item);
          }
        });
      }
    },
    setTableSelectionState() { //设置选中状态
      this.tableData.forEach(row => {
        const checked = !!this.getMatchRow(row);
        this.$refs.table.toggleRowSelection(row, checked);
      });
    },
    getParams() {
      const params = {};
      params.page = this.pageInfo.current;
      params.limit = this.pageInfo.pageSize;
      Object.assign(params, this.searchInfo);
      Object.assign(params, this.params);
      if (this.sort && this.order) {
        params.sort = this.sort;
        params.order = this.order;
      }
      return params;
    },
    getExportParams(searchFormData) {
      const params = {};
      params.page = 1;
      params.limit = 1000000;
      Object.assign(params, searchFormData);
      Object.assign(params, this.params);
      if (this.sort && this.order) {
        params.sort = this.sort;
        params.order = this.order;
      }
      let ids = this.checkedList.map(item => {
        return _.get(item, this.exportRowKey);
      });
      ids = _.uniq(ids);
      params.ids = ids.join(',');
      return params;
    },
    async loadTableData() {
      if (this.loading) {
        return;
      }
      const params = this.getParams();
      this.loading = true;
      let list = [];
      let total = 0;
      if (this.api) {
        let res = null;
        if (this.requestMethod === 'post') {
          res = await request.post(this.api, params);
        } else if (this.requestMethod === 'get') {
          res = await request.get(this.api, params);
        }
        res = _.get(res, 'res');
        list = _.get(res, 'data');
        if (!Array.isArray(list)) {
          list = _.get(res, 'data.rows');
        }
        total = _.get(res, 'data.total') || 0;
      } else {
        console.error('缺少api参数');
      }
      list = Array.isArray(list) ? list : [];
      this.tableData = list;
      await this.$nextTick();
      setTimeout(() => {
        this.loading = false;
      }, 500);
      //
      this.pageInfo.pageCount = Math.ceil(total / this.pageInfo.pageSize);
    },
    async handleSearch(searchFormData) {
      if (this.loading) {
        return;
      }
      this.searchInfo = searchFormData;
      this.pageInfo.current = 1; //搜索后重置到第一页
      this.checkedList = []; //搜索后清空选中的项
      await this.loadTableData();
      await this.$nextTick();
      this.$refs.table.clearSelection(); // 搜索后清空选中的项
    },
    async export(searchFormData) {
      const params = this.getExportParams(searchFormData);
      let apiUrl = ''
      if (this.exportApi) {
        apiUrl = this.exportApi;
      } else if (this.api) {
        apiUrl = this.api.replace('/api/admin/', '/api/export/');
      }
      const { res } = await request.post(apiUrl, params);
      const filepath = _.get(res, 'data.filepath');
      filepath && window.open(filepath);
    },
    async handlePageCurrentChange(current) {
      if (this.loading) {
        return;
      }
      this.pageInfo.current = current;
      await this.loadTableData();
      await this.$nextTick();
      this.setTableSelectionState(); //改变当前页后,设置当前页的每一行选中状态
    },
    async handleSizeChange(size) {
        if (this.loading) {
            return;
        }
        this.pageInfo.pageSize = size;
        await this.loadTableData();
        await this.$nextTick();
    },
    async handleSortChange({ prop, order }) {
      if (prop && order === 'ascending') {
        this.sort = prop;
        this.order = 'ASC';
      } else if (prop && order === 'descending') {
        this.sort = prop;
        this.order = 'DESC';
      } else {
        this.sort = '';
        this.order = '';
      }
      await this.reloadCurrentPage();
    },
    async reloadCurrentPage() { //添加编辑成功后调用
      await this.loadTableData();
      await this.$nextTick();
      this.setTableSelectionState();
    },
    async handleResize(){
        this.tableHeight = 0;
        await this.$nextTick();
        const _height = this.$refs.searchWrap.getBoundingClientRect().height;
        const _height2 = this.$refs.pageBarBox.getBoundingClientRect().height;
        let _rtol = 0;
        //分析报表顶部有总览数据时计算表格高度减值 总览数据可展开收起
        if(this.showTotal && this.showTotal.show){
            _rtol = 175;
        }
        //组织架构 显示上下级表格时
        if(this.params && this.params.upid){
            _rtol += 34;
        }
        const outtab = (this.$route.name=='chatdealer')?103:140;
        // console.log("this.params=>>>>>", this.params);
        // console.log(window.innerHeight, _height, _height2, _rtol);
        this.tableHeight = (window.innerHeight - _height - _height2 - _rtol - outtab) +'px';
        const ttimer = setTimeout(()=>{
            this.$refs.table.doLayout();
            clearTimeout(ttimer);
        },300)
    }
  },
  mounted() {
    this.loadTableData();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  created() {
    this.$globalData.$on('pv-list-reload-current', this.reloadCurrentPage);
  },
  beforeDestroy() {
    this.$globalData.$off('pv-list-reload-current', this.reloadCurrentPage);
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.pv-list {
  font-size: 0;
  line-height: 1;

  :deep {
    .el-table th.el-table__cell {
      padding: 4px 0;
      background-color: #4c5660;

      .cell {
        color: #ffffff;
      }
    }

    .el-table .el-table__cell {
      text-align: center;
      font-size: 12px;
      color: #222222;

      .cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
      }
    }

    .ctrl-links .el-link {
      margin: 0 10px;
      font-size: 13px;
    }

    .el-table__fixed-right::before {
      background-color: transparent;
    }

    .el-table .caret-wrapper{
        height: 23px;
        margin-right: -12px;
    }
    .el-table .sort-caret.ascending{
        top: 0;
    }
    .el-table .sort-caret.descending{
        bottom: 0;
    }
  }
}

.search-wrap {
  background-color: #ffffff;
  border-radius: 0 8px 0 0;
  overflow: hidden;
}
.table-wrap {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}

.page-bar-box {
  text-align: center;
  margin: 20px 0 0;
}
</style>
