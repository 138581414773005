<template>
  <span>{{ numFix }}</span>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    value: Number,
    time: { type: Number, default: 3 }
  },
  data() {
    return {
      showNumber: 0,
      lastChangeTime: 0,
      timeout: null
    };
  },
  computed: {
    stepCount() {
      //每毫秒增加的数字大小 最小1
      let count = this.value / this.time;
      count = Math.ceil(count);
      return count < 1 ? 1 : count;
    },
    numFix() {
      let numStr = `${this.showNumber}`;
      if (numStr.length > 3) {
        numStr = _.chunk(numStr.split('').reverse(), 3).map(chunk => {
          return chunk.join('');
        }).join(',').split('').reverse().join('');
      }
      return numStr;
    }
  },
  methods: {
    startChange() {
      if (this.showNumber < this.value) {
        let times = 1;
        if (this.lastChangeTime) {
          times = Date.now() - this.lastChangeTime;
        }
        this.lastChangeTime = Date.now();
        this.showNumber = this.showNumber + this.stepCount * times;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.startChange();
        }, 20);
      } else {
        this.showNumber = this.value;
        this.lastChangeTime = 0;
      }
    }
  },
  mounted() {
    this.startChange();
  }
}
</script>

<style lang="scss" scoped></style>
