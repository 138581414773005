<template>
  <div>
    <el-upload
        :class="{
            'pv-uploader': true,
            'drag-uploader': tempFileList.length>0?false:true
        }"
        v-loading="uploading"
        ref="upload"
        :drag="tempFileList.length>0?false:true"
        action=""
        list-type="picture-card"
        :file-list="tempFileList"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        multiple 
        :limit="100" 
        :on-exceed="handleExceed" 
        :on-change="handleChange"
        :accept="accept">
        <i v-if="tempFileList.length>0" slot="default" class="el-icon-plus"></i>
        <div v-if="tempFileList.length>0" slot="file" slot-scope="{file}" style="width: 100%;height: 100%;position: relative;">
            <!--单个文件上传进度-->
            <div class="progress-wrap" v-if="!file.id">
                <el-progress :width="100" type="circle" :percentage="file.percentage" :status="file.progressStatus || undefined"/>
            </div>
            <!--文件已上传显示缩略图和删除按钮-->
            <template v-else>
                <img v-if="['rar', 'zip', '7z'].includes(file.ext)"
                    class="thumb-img"
                    :src="imgSrc"
                ><!--压缩文件默认显示压缩包图标-->
                <el-image v-else class="el-upload-list__item-thumbnail" :src="file.url" fit="cover"></el-image>
                <div v-if="file.type && file.type.indexOf('video')>-1" class="cover-box"><i class="play-icon"></i></div>
                <span class="el-upload-list__item-actions">
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                    >
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
                <div class="upload-file-name">{{ file.name }}</div>
            </template>
        </div>
        <div v-if="tempFileList.length==0" class="upload-inner-box">
            <template>
                <i class="upload-icon"></i>
                <div class="up-text">拖拽文件到这里或<em>点击添加</em></div>
            </template>
        </div>
    </el-upload>
    <div class="tip-text">{{ tipText }}</div>
  </div>
</template>

<script>
import { ossUploadFile } from '@/service/public/upload';

export default {
  props: {
    value: String,
    accept: {
      type: String,
      default: 'image/jpg,image/jpeg,image/png,image/gif,video/mp4'
    },
    imageSizeLimit: { //图片默认不超过10M 厂家文件-图片100M
      type: Number,
      default: 10 * 1024 * 1024
    },
    videoSizeLimit: { //视频默认不超过100M 厂家文件-视频1G
      type: Number,
      default: 100 * 1024 * 1024
    },
    otherSizeLimit: { //其它文件格式不超过10M 厂家文件-压缩包2G
      type: Number,
      default: 10 * 1024 * 1024
    },
    description: String
  },
  data() {
    return {
      imgSrc: require('@/assets/images/home/archive-icon.png'),
      uploading: false,
      tempFileList: [],
    };
  },
  computed: {
    disabled() {
      return false;
    },
    acceptTypes() {
      return this.accept.split(',').map(item => item.trim().toLocaleLowerCase());
    },
    tipText() {
      if (this.description) {
        return this.description;
      }
      const imgTypes = this.acceptTypes.filter(item => item.indexOf('image') === 0).map(item => item.split('/')[1]);
      const videoTypes = this.acceptTypes.filter(item => item.indexOf('video') === 0).map(item => item.split('/')[1]);
      const otherTypes = this.acceptTypes.filter(item => item.indexOf('image') !== 0 && item.indexOf('video') !== 0).map(item => item.split('/')[1]);
      const imgSize = (this.imageSizeLimit / 1024 / 1024).toFixed(2) * 1;
      const videoSize = (this.videoSizeLimit / 1024 / 1024).toFixed(2) * 1;
      const otherSize = (this.otherSizeLimit / 1024 / 1024).toFixed(2) * 1;
      let text = '支持';
      const typeTexts = [];
      if (imgTypes.length > 0) {
        typeTexts.push('图片格式' + imgTypes.join('，') + `（${imgSize}M以内）`);
      }
      if (videoTypes.length > 0) {
        typeTexts.push('视频格式' + videoTypes.join('，') + `（${videoSize}M以内）`);
      }
      if (otherTypes.length > 0) {
        typeTexts.push('其它格式' + otherTypes.join('，') + `（${otherSize}M以内）`)
      }
      text += typeTexts.join('；');
      return text;
    }
  },
  methods: {
    beforeUpload(file) {
      //上传文件前验证文件格式和文件大小
      // ['image/jpg','image/jpeg','image/png','image/gif','video/mp4']
      const fileType = file.type.toLocaleLowerCase();
      const fileSize = file.size;
      const isImage = fileType.split('/')[0] === 'image';
      const isVideo = fileType.split('/')[0] === 'video';
      // console.log(this.acceptTypes, fileType, file);
      const extName = file.name.toLocaleLowerCase().split('.').pop();
      const accessExtNames = this.acceptTypes.map(item => {
        return item.split('/').pop()
      }).map(item => {
        return item.split('.').pop().toLocaleLowerCase();
      });
      // console.log(accessExtNames, extName);
      if (!accessExtNames.includes(extName)) { //文件类型不支持
        // this.$emit('fileTypeError');
        this.$message({
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: `请上传指定的文件类型<br/>${this.tipText}!`
        });
        return false;
      } else if (isImage && fileSize > this.imageSizeLimit) { //图片超过尺寸
        this.$emit('fileOverSize');
        const imgSize = (this.imageSizeLimit / 1024 / 1024).toFixed(2) * 1;
        this.$message.error(`上传图片大小不能超过 ${imgSize}MB!`);
        return false;
      } else if (isVideo && fileSize > this.videoSizeLimit) { //视频超过尺寸
        this.$emit('fileOverSize');
        const videoSize = (this.videoSizeLimit / 1024 / 1024).toFixed(2) * 1;
        this.$message.error(`上传本地视频大小不能超过${videoSize}MB!`);
        return false;
      } else if (!isImage && !isVideo && fileSize > this.otherSizeLimit) { //
        const otherSize = (this.otherSizeLimit / 1024 / 1024).toFixed(2) * 1;
        this.$message.error(`上传文件大小不能超过${otherSize}MB!`);
        return false;
      }
      return true;
    },
    handleProgress(percent, _checkpoint, data) {
        //分片上传的进度和断点信息
        // console.log('进度：', percent, '分片文件：', _checkpoint, '原始文件：', data.file.uid, data.file);
        const _percentage = (percent * 100).toFixed(2) * 1;
        const _index = this.tempFileList.findIndex(f=>f.uid == data.file.uid);
        if(_index>-1){
            //单文件上传进度更新percentage
            const tempfv = Object.assign({},this.tempFileList[_index], { percentage: _percentage});
            this.$set(this.tempFileList, _index, tempfv);
        }
    },
    uploadFile(data) {
      ossUploadFile(data, {
        onProgress: (p, _checkpoint) => { //上传进度
          this.handleProgress(p, _checkpoint, data);
        },
        onSuccess: (res) => { //上传成功-更新对应文件信息
            // console.log(res);
            const _index = this.tempFileList.findIndex(f=>f.uid == res.fileuid);
            if(_index>-1){
                const tempfv = Object.assign({},this.tempFileList[_index],{
                    id: res.data.id,
                    ext: res.data.ext,
                    type: res.data.type,
                    progressStatus: 'success'
                },res.data.type=='video'?{url: res.data.thumb}:{});
                this.$set(this.tempFileList, _index, tempfv);
            }
            // console.log(this.tempFileList);
            this.$emit('upload-success', this.tempFileList);
        },
        onError: (errMsg) => { //上传失败
            this.$message.error(errMsg);
            const _index = this.tempFileList.findIndex(f=>f.uid == data.file.uid);
            if(_index>-1){
                const tempfv = Object.assign({},this.tempFileList[_index],{
                    progressStatus: 'exception'
                });
                this.$set(this.tempFileList, _index, tempfv);
            }
        }
      });
    },
    handleRemove(file){
      //删除文件-id==file_id
    //   console.log("handleRemove", file);
      this.tempFileList.forEach(function (item, index, arr) {
        if (item.id == file.id && item.name == file.name && item.size == file.size) {
          arr.splice(index, 1);
        }
      });
      this.$emit("upload-success", this.tempFileList);
    },
    handleChange(files, fileList) {
      this.tempFileList = fileList;
    },
    //上传文件超出个数限制
    handleExceed(files, fileList) {
    //   console.log("handleExceed", files.length, fileList.length);
      this.$message.warning(`当前限制选择 100 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
  }
}
</script>

<style lang="scss" scoped>
.pv-uploader {
  max-height: 325px;
  overflow-y: auto;
  :deep {
    .el-upload-dragger {
      width: 100%;
      height: 100%;

      &:focus {
        border: 1px dashed transparent;
      }

      &.is-dragover {
        border: 1px dashed #0f86ea;
      }
    }
    .el-upload-list {
        .image-slot, .image-slot{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: #f5f7fa;
            color: #909399;
            font-size: 14px;
        }
    }
    .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete{
        position: absolute;
    }
  }
  &.drag-uploader{
    display: inline-block;
    width: 565px;
    height: 325px;
    background-color: #f4f4f4;
    border-radius: 5px;
    :deep {
        .el-upload-list {
            display: none;
        }
        .el-upload {
            width: 100%;
            height: 100%;
            border: 1px dashed transparent;
            background-color: transparent;

            &:focus {
                border: 1px dashed transparent;

                .el-upload-dragger {
                border: 1px dashed transparent;
                }
            }
        }
        .el-upload-dragger {
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 1px dashed transparent;
        }
    }
  }
}

.progress-wrap {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
    cursor: default;
    :deep {
      .el-progress {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        transform: translate3d(-50%, -50%, 0);
        background-color: #ffffff;
        overflow: hidden;
        border-radius: 50%;
        .el-progress__text{
            .el-icon-check{
                color: #8c939d;
            }
        }
      }
    }
}
.cover-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    .play-icon {
        position: absolute;
        display: block;
        width: 60px;
        height: 60px;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        margin-top: -30px;
        border-radius: 50%;
        cursor: pointer;
        background-image: url("@/assets/images/home/video-play-icon.png");
        background-size: 100% 100%;
        opacity: 0.8;
    }
}
.upload-inner-box {
  .upload-icon {
    background-image: url("@/assets/images/home/upload-icon.png");
    display: inline-block;
    vertical-align: top;
    width: 115px;
    height: 82px;
    margin-top: 70px;
  }

  .up-text {
    font-size: 17px;
    color: #575f6a;

    em {
      color: #3d82fc;
      cursor: pointer;
      font-style: normal;
    }
  }
}
.thumb-img{
    margin-top: calc(50% - 40px);
    margin-left: calc(50% - 40px);
}
.upload-file-name{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 12px;
    line-height: 1.2;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color: #ffffff;
}
.tip-text {
    margin-top: 15px;
    font-size: 12px;
    color: #898989;
}
</style>
