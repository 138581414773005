export default {
  userOperate: {
    //获取用户操作日志列表
    GET_LIST: '/api/admin/log/login/member'
  },
  login: {
    //获取登录日志列表
    GET_LIST: '/api/admin/log/login/list'
  }
}
