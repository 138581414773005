//任务中心接口

// 主机端-任务管理-列表：任务中心-列表
// 主机端-任务管理-详情列表：单任务多经销商列表
// 主机端-经销商任务-列表：多任务多经销商列表
// 主机端-经销商任务-详情列表：单经销商多任务列表
// 经销商端-任务中心-列表：单经销商多任务列表
// 经销商端-任务中心-详情列表：单任务多顾问列表

export default {
  //主机端
  host: {
    //任务管理
    taskAdmin: {
      //获取列表
      GET_LIST: '/api/admin/material/task/list',
      //获取详情   单任务多经销商列表
      DETAIL: '/api/admin/material/task/info/dealer/list',
      //新增任务
      ADD: '/api/admin/material/task',
      //编辑任务
      UPDATE: '/api/admin/material/task/update',
      //删除任务(批量)
      DELETE: '/api/admin/material/task/delete'
    },
    //经销商任务
    dealerTask: {
      //获取列表   多任务多经销商列表
      GET_LIST: '/api/admin/material/task/dealer/list',
      //获取详情   单经销商多任务列表
      DETAIL: '/api/admin/material/dealer/info/task/list',
    },
  },
  //经销商端
  dealer: {
    //任务管理
    taskAdmin: {
      //获取列表  单经销商多任务列表
      GET_LIST: '/api/admin/material/dealer/info/task/list',
      //获取详情   单任务多顾问列表
      DETAIL: '/api/admin/material/task/info/member/list',
    },
  }
}
