//本地测试
const sY_apiUrl = 'https://bhmc.chezhuliu.com';
const sY_aliossConfig = {
    accessKeyId: 'LTAI4FocgSbYqAML37efnNy5',
    accessKeySecret: 'u79UvP86Hss3J1N9WtIoJH5CgBUT9I',
    bucket: 'symat-bhmc',
    endpath: 'pvsymat/',
    region: 'oss-cn-hangzhou'
}

//北现测试服及正式服
let bhmc_apiUrl = null;
const bhmc_aliossConfig = {
    accessKeyId: 'LTAI5tGBfgA3RDjmxRAFcMNe',
    accessKeySecret: 'gTIiaMWl3KlaViTJO2Lj9Wo7EPulMA',
    bucket: 'oss-prod-bhmc-sc',
    endpath: 'scrm-sc/',
    region: 'oss-cn-beijing'
}

let isBHMC = false;
console.log(location.protocol+'//'+location.host);
if(location.host === 'scrm-dev-sc.bhmc.com.cn' || location.host === 'scrm-dev-sc-c.bhmc.com.cn' || location.host === 'scrm-sc.bhmc.com.cn' || location.host === 'scrm-sc-c.bhmc.com.cn'){
    isBHMC = true;
    bhmc_apiUrl = location.protocol+'//'+location.host;
}

export default {
  tokenKey: 'Admin-Token',
  copyright: {
    text: 'Copyright © 2012-2023 **** 版权所有',
    url: ''
  },
  /**
   * 阿里云OSS
   */
  aliossConfig: isBHMC? bhmc_aliossConfig:sY_aliossConfig,
  apiUrl: isBHMC? bhmc_apiUrl:sY_apiUrl
}

// HOST = https://symat-bhmc.oss-cn-hangzhou.aliyuncs.com/
// BUCKET = symat-bhmc

/**
 * scrm-dev-sc.bhmc.com.cn
scrm-dev-sc-c.bhmc.com.cn
scrm-sc.bhmc.com.cn
scrm-sc-c.bhmc.com.cn
 */
/**
 * #ACCESS_KEY_ID = LTAI5tFyT6Xjk8zMUPMfEYSm
#ACCESS_KEY_SECRET = qZwRCPgaDG2DaH3x63Cq2HWrXA7v1F
#HOST = https://oss-prod-bhmc-sc.oss-cn-beijing.aliyuncs.com/
#ENDPOINT = http://oss-cn-beijing.aliyuncs.com
#BUCKET = oss-prod-bhmc-sc
#ENDPATH = scrm-sc/
 */