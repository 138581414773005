<template>
  <div class="pv-pagination" :class="{disabled}">
    <el-select :value="pageSize" placeholder="请选择" size="small" style="width: 100px;margin-right: 3px;" :popper-append-to-body="false" @change="pageSizeChange">
      <el-option v-for="item in pageOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <!-- <span>{{ pageSize }}条/页</span> -->
    <a class="pv-page-item" @click="handlePrevClick"><i class="el-icon-arrow-left"></i>上一页</a>
    <template v-if="firstEnable">
      <a class="pv-page-item num-item" @click="handleNumClick(1)">1</a>
    </template>
    <template v-if="leftPointsEnable">
      <span class="pv-page-points">...</span>
    </template>
    <a class="pv-page-item num-item" v-for="item in showPages" :key="item" :class="{active:item===current}" @click="handleNumClick(item)">{{ item }}</a>
    <template v-if="rightPointsEnable">
      <span class="pv-page-points">...</span>
    </template>
    <template v-if="lastEnable">
      <a class="pv-page-item num-item" @click="handleNumClick(lastPage)">{{ lastPage }}</a>
    </template>
    <a class="pv-page-item" @click="handleNextClick">下一页<i class="el-icon-arrow-right"></i></a>
    <span class="num-view">
      <span class="current-num">{{ current }}</span>
      <span>/{{ lastPage }}</span>
    </span>
    <div class="fast-jump">
      <span>到第</span>
      <input v-model="fastCurrent" />
      <span>页</span>
      <div class="fast-btn" @click="handleJumpPage">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: Number, //当前第几页
    pageCount: Number, //总共有几页
    pageSize: Number, //每页显示条数
    disabled: Boolean //
  },
  data() {
    return {
      fastCurrent: '',
      pageOptions: [{
        value: 50,
        label: '50条/页'
      }, {
        value: 100,
        label: '100条/页'
      }, {
        value: 200,
        label: '200条/页'
      }, {
        value: 300,
        label: '300条/页'
      }]
    };
  },
  computed: {
    lastPage() {
      return this.pageCount < 1 ? 1 : this.pageCount;
    },
    leftPointsEnable() {
      return this.current >= 6;
    },
    rightPointsEnable() {
      return this.current <= this.lastPage - 5;
    },
    firstEnable() {
      if (this.showPages.includes(1)) {
        return false;
      }
      return true;
    },
    lastEnable() {
      if (this.showPages.includes(this.lastPage)) {
        return false;
      }
      return true;
    },
    showPages() {
      const pages = [];
      for (let i = 1; i <= this.lastPage; i++) {
        const xPage = Math.abs(this.current - i);
        if (xPage <= 3) {
          pages.push(i);
        }
      }
      if (pages.length === 0) {
        pages.push(1);
      }
      return pages;
    }
  },
  methods: {
    pageSizeChange(val) {
        if (val !== this.pageSize) {
            this.$emit('changeSize', val);
        }
    },
    handleJumpPage() {
      const target = this.fastCurrent * 1;
      if (target >= 1 && target <= this.lastPage) {
        this.handleNumClick(target);
      }
    },
    handleNumClick(num) {
      if (num !== this.current) {
        this.$emit('change', num);
      }
    },
    handlePrevClick() {
      let num = this.current - 1;
      num < 1 && (num = 1);
      if (num !== this.current) {
        this.$emit('change', num);
      }
    },
    handleNextClick() {
      let num = this.current + 1;
      num > this.lastPage && (num = this.lastPage);
      if (num !== this.current) {
        this.$emit('change', num);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pv-pagination {
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  user-select: none;

  &.disabled {
    cursor: wait;

    .pv-page-item {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

:deep .el-select-dropdown__item{
  font-size: 12px;
  text-align: left;
}
:deep .el-input__inner {
  font-size: 12px;
}

.pv-page-item {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  color: #3e3e3e;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  margin-right: 4px;
  transition: 0.28s all ease-in-out;
  .el-icon-arrow-left {
    margin-right: 5px;
  }
  .el-icon-arrow-right {
    margin-left: 5px;
  }

  &.num-item {
    width: 40px;
  }

  &:hover {
    color: #3d82fc;
    border-color: #3d82fc;
    background-color: #ecf5ff;
  }

  &.active {
    background-color: #3d82fc;
    color: #ffffff;
    border-radius: 3px;
  }
}

.pv-page-points {
  font-size: 12px;
  color: #686868;
  line-height: 32px;
  padding: 0 10px;
  text-align: center;
}

.num-view {
  font-size: 12px;
  color: #101010;
  margin: 0 15px;
  line-height: 32px;

  .current-num {
    color: #3d82fc;
  }
}

.fast-jump {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  color: #808080;
  line-height: 32px;

  input {
    width: 34px;
    height: 30px;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid #dcdfe6;
    outline: none;
    font-size: 13px;
    color: #3e3e3e;
    margin: 0 15px;
    border-radius: 3px;
  }

  .fast-btn {
    display: inline-block;
    vertical-align: top;
    width: 53px;
    height: 32px;
    line-height: 32px;
    background-color: #ffffff;
    text-align: center;
    border: 1px solid #dcdfe6;
    color: #3e3e3e;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: 0.28s all ease-in-out;

    &:hover {
      color: #3d82fc;
      border-color: #3d82fc;
      background-color: #ecf5ff;
    }
  }
}
</style>
